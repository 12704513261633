import React, { useState, useEffect } from 'react';
import { Avatar, List ,Spin} from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/avatar/chatUser.png";
import { useTranslation } from 'react-i18next';
const ChatBar = ({ socket }) => {
  const {t} = useTranslation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let userType = localStorage.getItem('type') === 'e' ? '4' : '3';
  let userId = JSON.parse(localStorage.getItem("USER_INFO")) || {};
  // const [selectedTab, setSelectedTab] = useState(null); // State to manage selected tab
  useEffect(() => {
    setLoading(true);
   setTimeout(() => {
    setUsers([]);
    userType = localStorage.getItem('type') === 'e' ? '4' : '3';
    userId = JSON.parse(localStorage.getItem("USER_INFO")) || {};
    console.log(userId,"userId")
    setLoading(true);
    const obj = { cid: userId?.companyId?._id, target: userType }
    socket.emit('getUserList', obj);
    socket.on('getUserList', (data) => {
      let usersList = [];
      if(data) usersList =  data.filter(item => item !== null);
      console.log(usersList)
      setUsers(usersList);
      setLoading(false);
      // setSelectedTab(2);
      // onUserSelect(data[2]);
    });
   }, 1000);
    // if(!users.length) {
    // const obj = { cid: userId?.companyId?._id, target: userType }
    // socket.emit('getUserList', obj);
    // socket.on('getUserList', (data) => {
    //   console.log(data)
    //   setUsers(data);
    //   // setSelectedTab(2);
    //   // onUserSelect(data[2]);
    // });
  // }
  
  // window.addEventListener('storage', handleStorageChange);

  // return () => {
  //   // Remove event listener on cleanup
  //   window.removeEventListener('storage', handleStorageChange);
  // };
  }, []);
  const handleTabClick = (user,index) => {
    const id = `${user.conversation_id || ''}--${user.emp_id || user.client_id}--${user.full_name || ''}--${user.userType || ''}`;
    history.push(`/chat_body/${id}`);
    // console.log(user,"user")
    // setSelectedTab(index); // Update selected tab when a tab is clicked
    // onUserSelect(user);
    //className={index === selectedTab ? 'selected' : ''}
  };
  // const customizeRenderEmpty = () => (
  //   <div style={{ textAlign: 'center' }}>
  //     <SmileOutlined style={{ fontSize: 20 }} />
  //     <p>Data Not Found</p>
  //   </div>
  // );
  // || 'https://api.dicebear.com/7.x/miniavs/svg?seed=$'}${index}
  return (
    <div className="chat__sidebar">
         <Spin
      size="large"
      spinning={loading}
      fullscreen
    />
      {/* <h2>Open Chat</h2> */}
      <div>
        {/* <h4 className="chat__header">{t('Chats')}</h4> */}
        <List
          // renderEmpty={customizeRenderEmpty}
          itemLayout="horizontal"
          dataSource={users}
          renderItem={(item, index) => (
            <List.Item onClick={() => handleTabClick(item,index)}>
              <List.Item.Meta
                avatar={<Avatar src={item.profile ? item.profile : logo} />}
                // avatar={<Avatar src={`${item.profile}`} />}
                title={<a href="Javascript:;">{item.full_name}</a>}
                description={<span style={{color: item.status == 'online' ? 'green' : 'red' }}>{item.status}</span>}
              />
              <div style={{color:'red' }}>{item.unread}</div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default ChatBar;